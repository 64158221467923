import SecuredApiService from "./Api/SecuredApiService";
import Urls from "../Consts/Urls";
import AuthService from "./AuthService";

export default class InventoryService {

    apiService;

    constructor() {
        this.apiService = new SecuredApiService();
        this.authService = new AuthService();
    }

    getInventory() {
        return this.apiService.get(Urls.inventories + '/id_gestiune=' + this.authService.getIdGestiune());
    }

    async updateStocfaptic(articleId, value) {
        return this.apiService.put('/Inventar?id=' + articleId + '&stocfaptic=' + value);
    }

    getInventoryRow(id) {
        return this.apiService.get(Urls.inventories + '/id=' + id);
    }
}