export default class FilterService {

    getFilteredItems(id, inventory, mandatoryInventory) {
        let items = {};
        if (id === 'root-0') {
            items = this.getAllProducts(id, inventory, mandatoryInventory);
        } else if (id.includes('sub-')) {
            items = this.getSubgroupItems(id, inventory, mandatoryInventory);
        } else {
            items = this.getGroupItems(id, inventory, mandatoryInventory);
        }
        return items;
    }

    getAllProducts(id, inventory, mandatoryInventory) {
        const items = inventory.filter(item => {
            if (item.stocfaptic === null) {
                item.stocfaptic = '';
            }
            if (mandatoryInventory === true) {
                return item.oblig === true;
            } else {
                return true;
            }
        });
        return {
            items: items,
            unfilteredGridData: items
        }
    }

    getGroupItems(id, inventory, mandatoryInventory) {
        const idFilter = parseInt(id);
        const items = inventory.filter(item => {
            const idGroup = parseInt(item.id_grupa);
            if (item.stocfaptic === null) {
                item.stocfaptic = '';
            }
            if (mandatoryInventory === true) {
                return idGroup === idFilter && item.oblig === true;
            } else {
                return idGroup === idFilter;
            }
        });
        const unfilteredGridData = inventory.filter(item => {
            return parseInt(item.id_grupa) === idFilter;
        });
        return {
            items: items,
            unfilteredGridData: unfilteredGridData
        }
    }

    getSubgroupItems(id, inventory, mandatoryInventory) {
        const subId = parseInt(id.split('-')[1]);
        // get items
        const items = inventory.filter(item => {
            if (item.stocfaptic === null) {
                item.stocfaptic = '';
            }
            if (mandatoryInventory === true) {
                return item.id_subgrupa === subId && item.oblig === true;
            } else {
                return item.id_subgrupa === subId;
            }
        });
        const unfilteredGridData = inventory.filter(item => {
            return item.id_subgrupa === subId;
        });
        return {
            items: items,
            unfilteredGridData: unfilteredGridData
        }
    }
}