import React from 'react';
import {DataGrid} from '@mui/x-data-grid';
import {TextField} from "@mui/material";
import "./Style/ArticleGridMobile.scss";

export default class ArticleGridMobile extends React.Component {

    columns;

    constructor(props) {
        super(props);
        this.state = {
            grower: false,
        };
    }

    onArticleFieldChange(event) {
        this.props.parentCallback(event.target.value);
    }

    onBarCodeFieldChange(event) {
        this.props.onBarCodeSearch(event.target.value);
    }

    async onProcessRowUpdate(newRecord) {
        return await this.props.stocfapticSave(newRecord.id, newRecord.stocfaptic);
    }

    render() {
        this.columns = [];
        let barCode = '';
        this.columns.push({
            field: 'den_art',
            headerName: 'Articol',
            flex: 1.2,
            editable: false,
            renderCell: (params) => {
                let barCode = '';
                let stocscriptic = '';
                if (this.props.showBarCode) {
                    barCode = 'Cod Bare: ' + params.row.codbare;
                }
                if (this.props.showScriptic) {
                    stocscriptic = 'Stoc Scriptic: ' + params.row.stocscriptic;
                }

                return (
                    <div style={{lineHeight: '1.1em'}}>
                        <strong>{params.value}</strong><br/>
                        {barCode}<br/>
                        {stocscriptic}
                    </div>

                )
            },
        });
        this.columns.push({
            field: 'um',
            headerName: 'UM',
            flex: 0.4,
            editable: false,
        });
        this.columns.push({
            field: 'stocfaptic',
            headerName: 'Faptic',
            type: 'number',
            flex: 0.5,
            editable: true,
            renderCell: (item) => {
                const value = parseFloat(item.row.stocfaptic);
                if (isNaN(value)) {
                    return '';
                }
                return value.toFixed(this.props.decimals);
            }
        });
        if (this.props.showBarCode) {
            barCode = <TextField
                className="col-5"
                label="Căutare codebare"
                variant="standard"
                onChange={this.onBarCodeFieldChange.bind(this)}
            />
        }
        return (
            <div style={{height: '80vh', width: '100%'}}>
                <div className="search-box-container row">
                    <TextField
                        className="col-5"
                        label="Căutare articol"
                        variant="standard"
                        onChange={this.onArticleFieldChange.bind(this)}
                    />
                    <div className="col-2"/>
                    {barCode}
                </div>
                <div className="divider"/>
                <DataGrid
                    rows={this.props.articles}
                    columns={this.columns}
                    pageSize={parseInt(this.props.pageSize)}
                    rowsPerPageOptions={[5]}
                    disableSelectionOnClick
                    autoPageSize
                    experimentalFeatures={{newEditingApi: true}}
                    processRowUpdate={this.onProcessRowUpdate.bind(this)}
                    rowHeight={70}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'den_art', sort: 'asc' }],
                        },
                    }}
                />
            </div>
        );
    }
}