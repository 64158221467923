import React from 'react';
import InventoryService from "../Services/InventoryService";
import ArticleGrid from "../Components/ArticleGrid/ArticleGrid";
import ArticleTree from "../Components/ArticleTree/ArticleTree";
import {Checkbox, FormControlLabel,} from "@mui/material";
import DrawerHeader from "../Components/Menu/Drawer/DrawerHeader";
import Main from "../Components/Menu/Drawer/Main";
import VenueAppBar from "../Components/Menu/UI/VenueAppBar";
import "./Styles/Dashboard.scss";
import UserParamsService from "../Services/UserParamsService";
import ArticleGridMobile from "../Components/ArticleGridMobile/ArticleGridMobile";
import FilterService from "../Services/FilterService";
import AuthService from "../Services/AuthService";

class Dashboard extends React.Component {

    inventoryService;
    userParamsService;
    filterService;
    authService;

    constructor(props) {
        super(props);
        this.state = {
            groups: {},
            groupsId: [],
            inventory: {},
            gridData: [],
            defaultGridData: [],
            unfilteredGridData: [],
            mandatoryInventory: false,
            open: false,
            showBarCode: false,
            showScriptic: false,
            searchArticle: '',
            searchBarCode: '',
            selectedId: 'root-0',
            decimals:2,
            pageSize:100,

        };
        this.inventoryService = new InventoryService();
        this.userParamsService = new UserParamsService();
        this.filterService = new FilterService();
        this.authService = new AuthService();
    }

    componentDidMount() {

        this.userParamsService.getParams().then((response) => {
            response.data.forEach((item) => {
                switch (item.id_param) {
                    case 11:
                        this.setState({decimals: parseInt(item.val_param)});
                        break;
                    case 21:
                        this.setState({showBarCode: parseInt(item.val_param) === 1});
                        break;
                    case 251:
                        this.setState({showScriptic: parseInt(item.val_param) === 1});
                        break;
                    default:
                        break;
                }
            });
            this.getInventoryData();
        });
    }

    getInventoryData(setRootNodeSelect = true) {
        this.inventoryService.getInventory().then((response) => {
            this.setState({groups: response.data.grupe, inventory: response.data.inventar});
            let groupsId = ['root-0'];
            this.state.groups.forEach(element => {
                groupsId.push(element.id_grupa.toString());
            });
            this.setState({groupsId: groupsId}, () => {
                if (setRootNodeSelect) {
                    this.onNodeSelect('root-0');
                }
            });
        });
    }

    onNodeSelect(id) {
        let itemsWithFilters = this.filterService.getFilteredItems(id, this.state.inventory, this.state.mandatoryInventory);

        this.setState({
            selectedId: id,
            gridData: itemsWithFilters.items,
            defaultGridData: itemsWithFilters.items,
            defaultFilteredGridData: itemsWithFilters.items,
            unfilteredGridData: itemsWithFilters.unfilteredGridData
        }, () => {
            if (this.state.searchArticle.length > 0 || this.state.searchBarCode.length > 0) {
                this.search();
            }
        });
    }


    search() {
        if (this.state.searchArticle.length > 0 || this.state.searchBarCode.length > 0) {
            let filteredItemsByArticle = [];
            let filteredItemsByBarCode = [];
            if (this.state.searchArticle.length > 0) {
                filteredItemsByArticle = this.state.defaultGridData.filter(article => {
                    return article.den_art.toLowerCase().search(this.state.searchArticle.toLowerCase()) > -1;
                });
            }
            if (this.state.searchBarCode.length > 0) {
                filteredItemsByBarCode = this.state.defaultGridData.filter(article => {
                    return article.codbare?.toLowerCase().search(this.state.searchBarCode.toLowerCase()) > -1;
                });
            }
            let filteredItems = [...filteredItemsByArticle, ...filteredItemsByBarCode];
            if (this.state.mandatoryInventory === true) {
                filteredItems = filteredItems.filter(item => item.oblig === true);
            }
            this.setState({gridData: filteredItems, defaultFilteredGridData: filteredItems});
        } else {
            this.onNodeSelect(this.state.selectedId);
        }
    }

    onArticleSearch(chars) {
        this.setState({searchArticle: chars}, this.search);
    }

    onBarCodeSearch(chars) {
        this.setState({searchBarCode: chars}, this.search);
    }

    onChangeForInventory(event) {
        this.setState({mandatoryInventory: event.target.checked});
        if (event.target.checked) {
            const filteredItems = this.state.gridData.filter(item => item.oblig === true);
            this.setState({gridData: filteredItems});
        } else {
            this.setState({gridData: this.state.unfilteredGridData});
        }
    }

    async updateStocfaptic(rowId, stocfaptic) {
        await this.inventoryService.updateStocfaptic(rowId, stocfaptic);
        await this.getInventoryData(false);
        let row = await this.inventoryService.getInventoryRow(rowId);
        return row.data[0];
    }

    handleDrawerOpen() {
        this.setState({open: true});
    }

    handleDrawerClose() {
        this.setState({open: false});
    }

    handleLogout() {
        this.authService.logout();
        window.location.reload();
    }

    render() {
        return (
            <React.Fragment>
                <VenueAppBar open={this.state.open}
                             setDrawerClose={this.handleDrawerClose.bind(this)}
                             setDrawerOpen={this.handleDrawerOpen.bind(this)}
                             onChangeForInventory={this.onChangeForInventory.bind(this)}
                             onNodeSelect={this.onNodeSelect.bind(this)}
                             groupsId={this.state.groupsId}
                             groups={this.state.groups}
                             onLogout={this.handleLogout.bind(this)}
                />
                <Main open={this.state.open}
                      className="vh-100 d-xs-block d-sm-block d-md-block d-lg-none d-xl-none d-xxl-none">
                    <DrawerHeader/>
                    <ArticleGridMobile
                        articles={this.state.gridData}
                        showScriptic={this.state.showScriptic}
                        showBarCode={this.state.showBarCode}
                        decimals={this.state.decimals}
                        pageSize={this.state.pageSize}
                        parentCallback={this.onArticleSearch.bind(this)}
                        stocfapticSave={this.updateStocfaptic.bind(this)}
                        onBarCodeSearch={this.onBarCodeSearch.bind(this)}
                    />
                </Main>
                <div className="container-large d-none d-lg-block d-xl-block d-xxl-block" style={{height: "90vh"}}>
                    <DrawerHeader/>
                    <div className="row" style={{height: "80vh"}}>
                        <div className="col-xs-4 col-md-4 col-lg-4">
                            <br/>
                            <ArticleTree groupsId={this.state.groupsId} groups={this.state.groups}
                                         onNodeSelect={this.onNodeSelect.bind(this)}/>
                            <FormControlLabel control={<Checkbox onChange={this.onChangeForInventory.bind(this)}/>}
                                              label="De inventariat"/>
                        </div>
                        <div className="col-xs-8 col-md-8 col-lg-8">
                            <ArticleGrid
                                articles={this.state.gridData}
                                showScriptic={this.state.showScriptic}
                                showBarCode={this.state.showBarCode}
                                decimals={this.state.decimals}
                                pageSize={this.state.pageSize}
                                parentCallback={this.onArticleSearch.bind(this)}
                                stocfapticSave={this.updateStocfaptic.bind(this)}
                                onBarCodeSearch={this.onBarCodeSearch.bind(this)}
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default Dashboard