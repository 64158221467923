import LocalStorage from "../Classes/AuthStorage/LocalStorage";

export default class AuthService {

    localStorage;

    constructor() {
        this.localStorage = new LocalStorage();
    }

    storeUser(loginData) {
        this.localStorage.add(loginData)
    }

    getToken() {
        return this.localStorage.getItemValue('token');
    }

    isAuthenticated() {
        return this.getToken() && this.getToken().length > 0;
    }

    getUserId() {
        return this.localStorage.getItemValue('id_user');
    }

    getIdGestiune() {
        return this.localStorage.getItemValue('id_gestiune');
    }

    logout() {
        this.localStorage.deleteAll();
    }
}