import SecuredApiService from "./Api/SecuredApiService";
import Urls from "../Consts/Urls";
import AuthService from "./AuthService";

export default class UserParamsService {

    apiService;
    authService;

    constructor() {
        this.apiService = new SecuredApiService();
        this.authService = new AuthService();
    }

    getParams() {
        return this.apiService.get(Urls.params + '?id_user=' + this.authService.getUserId());
    }

}