import React, {useEffect, useState} from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import VenueThemes from "./Theme/VenueTheme";
import {CssBaseline, ThemeProvider} from "@mui/material";
import {BrowserRouter as Router} from 'react-router-dom';

import AppRoutes from "./Helpers/AppRoutes";
import {ToastProvider} from "react-toast-notifications";
import OfflineModal from "./Components/Offline/OfflineModal";

const App = () => {
    let [online, isOnline] = useState(navigator.onLine);

    const setOnline = () => {
        console.log('We are online!');
        isOnline(true);
    };
    const setOffline = () => {
        console.log('We are offline!');
        isOnline(false);
    };

    useEffect(() => {
        window.addEventListener('offline', setOffline);
        window.addEventListener('online', setOnline);

        // cleanup if we unmount
        return () => {
            window.removeEventListener('offline', setOffline);
            window.removeEventListener('online', setOnline);
        }
    }, []);

    return (
        <ThemeProvider theme={VenueThemes}>
            <ToastProvider>
                <CssBaseline/>
                <div className="App" >
                    <Router>
                        <AppRoutes/>
                    </Router>
                    <OfflineModal display={!online} />
                </div>
            </ToastProvider>
        </ThemeProvider>
    );
}

export default App;


