import React from 'react';
import {Route, Routes} from "react-router-dom";
import Login from "../Pages/Login";
import AuthService from "../Services/AuthService";
import Dashboard from "../Pages/Dashboard";

const AppRoutes = () => {

    const authService = new AuthService();
    const isAuthenticated = authService.isAuthenticated();

    let routes = [];
    if (isAuthenticated) {
        routes.push(<Route exact path="/" key="DashboardRoute" element={<Dashboard key="Dashboard"/>}/>);
    } else {
        routes.push(<Route exact path="/" key="LoginRoute" element={<Login key="Login"/>}/>);
    }

    return (
        <Routes>
            {routes}
        </Routes>
    );
}

export default AppRoutes;