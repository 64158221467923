import React from 'react';
import TreeView from "@mui/lab/TreeView";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TreeItem from "@mui/lab/TreeItem";

export default class ArticleTree extends React.Component {


    onNodeSelect(node, id) {
        this.props.onNodeSelect(id);
    }

    render() {
        let tree = '';
        if (this.props.groups.length > 0) {
            tree = <TreeView
                key="treeview"
                aria-label="file system navigator"
                defaultCollapseIcon={<ExpandMoreIcon/>}
                defaultExpandIcon={<ChevronRightIcon/>}
                expanded={[...this.props.groupsId]}
                defaultSelected={['root-0']}
                sx={{flexGrow: 1, maxWidth: 400, overflowY: 'auto'}}
                onNodeSelect={this.onNodeSelect.bind(this)}
            >
                <TreeItem
                    key="treeItemRoot"
                    nodeId="root-0"
                    label="Toate Articolele">
                    {this.props.groups.map(
                        element => {
                            let subgroups = '';
                            if (element.subgrupe) {
                                subgroups = element.subgrupe.map(subgroup => {
                                    if (subgroup.constructor === Object) {
                                        return <TreeItem key={subgroup.den_subgrupa}
                                                         nodeId={"sub-" + subgroup.id_subgrupa.toString()}
                                                         label={subgroup.den_subgrupa}/>
                                    }
                                    return null;
                                })
                            }
                            return <TreeItem key={element.den_grupa} nodeId={element.id_grupa.toString()}
                                             label={element.den_grupa}>{subgroups}</TreeItem>;
                        })
                    }
                </TreeItem>

            </TreeView>
        }
        return tree;
    }
}