import React from 'react';
import {AppBar, Checkbox, Divider, Drawer, FormControlLabel, IconButton, Toolbar, Typography} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ArticleTree from "../../ArticleTree/ArticleTree";
import DrawerHeader from "../Drawer/DrawerHeader";
import "./Style/VenueAppBar.scss";
import {LogoutTwoTone} from "@mui/icons-material";


export default class VenueAppBar extends React.Component {

    handleDrawerOpen() {
        this.props.setDrawerOpen();
    }

    handleDrawerClose() {
        this.props.setDrawerClose();
    }

    onChangeForInventory(event) {
        this.props.onChangeForInventory(event);
    }

    onNodeSelect(id) {
        this.props.onNodeSelect(id);
    }

    onLogout() {
        this.props.onLogout();
    }

    render() {
        return (
            <React.Fragment>
                <AppBar position="fixed" open={this.props.open}>
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={this.handleDrawerOpen.bind(this)}
                            edge="start"
                            sx={{mr: 2, ...(this.props.open && {display: 'none'})}}
                            className="d-lg-none d-xl-none d-xxl-none"
                        >
                            <MenuIcon/>
                        </IconButton>
                        <Typography variant="h6" noWrap component="div">
                            Venue
                        </Typography>
                        <div className='grow'/>
                        <div className="">
                            <IconButton
                                edge="end"
                                aria-label="Ieșire"
                                color="inherit"
                                onClick={this.onLogout.bind(this)}
                            >
                                <LogoutTwoTone/>
                            </IconButton>
                        </div>
                    </Toolbar>
                </AppBar>
                <Drawer
                    sx={{
                        width: 240,
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: 240,
                            boxSizing: 'border-box',
                        },
                    }}
                    variant="persistent"
                    anchor="left"
                    open={this.props.open}
                >
                    <DrawerHeader>
                        <IconButton onClick={this.handleDrawerClose.bind(this)}>
                            <ChevronLeftIcon/>
                        </IconButton>
                    </DrawerHeader>
                    <Divider/>
                    <ArticleTree groupsId={this.props.groupsId} groups={this.props.groups}
                                 onNodeSelect={this.onNodeSelect.bind(this)}/>
                    <FormControlLabel className="to-inventory"
                                      control={<Checkbox onChange={this.onChangeForInventory.bind(this)}/>}
                                      label="De inventariat"/>
                </Drawer>
            </React.Fragment>
        );
    }
}