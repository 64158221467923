import React from 'react';
import Urls from "../Consts/Urls";
import AuthParamInterface from "../Classes/AuthStorage/AuthParamInterface";
import AuthService from "../Services/AuthService";
import axios from "axios";
import "./Styles/Login.scss";
import {useToasts} from "react-toast-notifications";
import logo from "../logo.png"


const Login = () => {
    const [formData, setFormData] = React.useState({den_user: '', psw_user: ''});
    const inputData = {};
    const authService = new AuthService();
    inputData.prototype = Object.create(AuthParamInterface);
    const {addToast} = useToasts();

    const onSubmit = (e) => {
        e.preventDefault();

        axios.post(Urls.serverUrl + Urls.login, formData)
            .then((res) => {
                if (res.status === 200) {
                    inputData.prototype.id_user = function () {
                        return res.data.id_user;
                    };
                    inputData.prototype.ci = function () {
                        return res.data.ci;
                    };
                    inputData.prototype.nume_user = function () {
                        return res.data.nume_user;
                    };
                    inputData.prototype.den_user = function () {
                        return res.data.den_user;
                    };
                    inputData.prototype.id_gestiune = function () {
                        return res.data.id_gestiune;
                    };
                    inputData.prototype.token = function () {
                        return res.data.token;
                    };
                    authService.storeUser(inputData);
                    addToast("Autentificare reușită", {appearance: 'success'});
                    window.location.reload();
                }
            }).catch(function (err) {
            addToast("Utilizator sau parolă greșită", {appearance: 'error'});
        });
    }

    return (
        <section className="vh-100">
            <div className="container-fluid h-custom">
                <div className="row d-flex justify-content-center align-items-center h-100">
                    <div className="col-md-9 col-lg-6 col-xl-4">
                        <img src={logo} className="img-fluid center-image" alt="Venue"/>
                    </div>
                    <div className="form-input-login col-md-8 col-lg-4 col-xl-2">
                        <form onSubmit={onSubmit} >

                            <div className="form-outline mb-4">
                                <input className="form-control form-control-lg" placeholder="Utilizator"
                                       type={"den_user"}
                                       onChange={(e) => setFormData({...formData, den_user: e.target.value})}/>
                            </div>

                            <div className="form-outline mb-3">
                                <input type={"psw_user"} className="form-control form-control-lg" placeholder="Parola"
                                       onChange={(e) => setFormData({...formData, psw_user: e.target.value})}/>
                            </div>
                            <div className="text-center text-lg-start mt-4 pt-2">
                                <button className="btn btn-primary btn-lg w-100">Login
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Login;