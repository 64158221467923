import {createTheme} from "@mui/material/styles";

const VenueThemes = createTheme({
    components: {
        MuiAppBar: {
            styleOverrides: {
                colorPrimary: {
                    backgroundColor: "#696950"
                }
            }
        },
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    backgroundColor: "#121212",
                    color: "#fff",
                }
            }
        }
    },
    palette: {
        mode: 'dark',
        background: {
            palette: "#121212",
            default: "#121212"
        },
        text: {
            primary: "#fff",
            secondary: "rgba(255,255,255,0.7)",
            disabled: "rgba(255,255,255,0.5)",
            icon: "rgba(255,255,255,0.5)"
        },
        type: 'dark',
    },
    typography: {
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
        ].join(','),
    },
    body: {
        color: "Red",
    }
});

export default VenueThemes;