import React from 'react';
import Modal from 'react-modal';

const customStyles = {
    content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)'
    }
};

Modal.setAppElement('#root');

const OfflineModal = (display) => {
    return (
        <Modal
            isOpen={display.display}
            style={customStyles}
            contentLabel="Network lost"
        >
            <div style={{color:'black'}}>Lipsă conexiune la rețea</div>
        </Modal>
    );
}

export default OfflineModal;