import LOCAL_STORAGE from "../../Consts/Constants";

export default class LocalStorage {
    add(inputData) {
        localStorage.setItem(LOCAL_STORAGE.idUser, inputData.prototype.id_user());
        localStorage.setItem(LOCAL_STORAGE.token, inputData.prototype.token());
        localStorage.setItem(LOCAL_STORAGE.idGestiune, inputData.prototype.id_gestiune());
        localStorage.setItem(LOCAL_STORAGE.numeUser, inputData.prototype.nume_user());
        localStorage.setItem(LOCAL_STORAGE.ci, inputData.prototype.ci());
        localStorage.setItem(LOCAL_STORAGE.denUser, inputData.prototype.den_user());
    }

    deleteKey(key) {
        localStorage.removeItem(key);
    }

    deleteAll() {
        localStorage.clear();
    }

    updateKey(key, value) {
        localStorage.setItem(key, value);
    }

    getItemValue(key) {
        return localStorage.getItem(key);
    }
}