import Axios from "axios";
import AuthService from "../Services/AuthService";
import Urls from "../Consts/Urls";

const authService = new AuthService();
const axiosInterceptor = Axios.create({
    baseURL: `${Urls.serverUrl}`,
    headers: {
        "Content-Type": "application/json",
        "Authorization": authService.getToken()
    },
});

axiosInterceptor.interceptors.request.use(
    (config) => {
        return Promise.resolve(config);
    },
    (error) => Promise.reject(error)
);

axiosInterceptor.interceptors.response.use(
    (response) => Promise.resolve(response),
    (error) => {
        return Promise.reject(error);
    }
);

export default axiosInterceptor;
