import axiosInterceptor from "../../Interceptors/AxiosInterceptor";
import Urls from "../../Consts/Urls";


export default class SecuredApiService {
    get(url) {
        return axiosInterceptor.get(Urls.serverUrl + url);
    }

    post(url, data) {
        return axiosInterceptor.post(Urls.serverUrl + url, data);
    }

   async put(url, data) {
        return axiosInterceptor.put(Urls.serverUrl + url, data);
    }

    patch(url, data) {
        return axiosInterceptor.patch(Urls.serverUrl + url, data);
    }

    delete(url) {
        return axiosInterceptor.delete(Urls.serverUrl + url);
    }
}