import React from 'react';
import {DataGrid} from '@mui/x-data-grid';
import {TextField} from "@mui/material";
import "./Style/ArticleGrid.scss";

export default class ArticleGrid extends React.Component {

    columns;

    constructor(props) {
        super(props);
        this.state = {
            grower: false,
        };
    }

    onArticleFieldChange(event) {
        this.props.parentCallback(event.target.value);
    }

    onBarCodeFieldChange(event) {
        this.props.onBarCodeSearch(event.target.value);
    }

    async onProcessRowUpdate(newRecord) {
        return await this.props.stocfapticSave(newRecord.id, newRecord.stocfaptic);
    }

    render() {
        this.columns = [];
        let barCode = '';
        this.columns.push({
            field: 'id',
            headerName: 'ID',
            flex: 0.2,
            editable: false,
            hide:true,
        })
        this.columns.push({
            field: 'den_art',
            headerName: 'Articol',
            flex: 1.2,
            editable: false,
        });
        if (this.props.showBarCode) {
            this.columns.push({
                field: 'codbare',
                headerName: 'Code bare',
                type: 'number',
                flex: 0.5,
                editable: false,
            });
            barCode = <TextField
                className="col-3"
                label="Căutare codebare"
                variant="standard"
                onChange={this.onBarCodeFieldChange.bind(this)}
            />
        }
        this.columns.push({
            field: 'um',
            headerName: 'UM',
            flex: 0.4,
            editable: false,
        });
        if (this.props.showScriptic) {
            this.columns.push({
                field: 'stocscriptic',
                headerName: 'Scriptic',
                type: 'number',
                flex: 0.5,
                editable: false,
                valueGetter: (item) => {
                    return item.row.stocfaptic;
                },
                renderCell: (item) => {
                    const value = parseFloat(item.row.stocfaptic);
                    if (isNaN(value)) {
                        return '';
                    }
                    return value.toFixed(this.props.decimals);
                }
            });
        }
        this.columns.push({
            field: 'stocfaptic',
            headerName: 'Faptic',
            type: 'number',
            flex: 0.5,
            editable: true,
            renderCell: (item) => {
                const value = parseFloat(item.row.stocfaptic);
                if (isNaN(value)) {
                    return '';
                }
                return value.toFixed(this.props.decimals);
            }
        });

        let grid = '';

        // if (this.props.articles.length > 0) {
            grid = <div style={{display: 'flex', height: '100%'}}>
                <div style={{flexGrow: 1}}>
                    <div className="search-box-container row">
                        <TextField
                            className="col-3"
                            label="Căutare articol"
                            variant="standard"
                            onChange={this.onArticleFieldChange.bind(this)}
                        />
                        <div className="col-6"/>
                        {barCode}
                    </div>
                    <div className="divider"/>
                    <DataGrid
                        rows={this.props.articles}
                        columns={this.columns}
                        pageSize={parseInt(this.props.pageSize)}
                        rowsPerPageOptions={[5]}
                        disableSelectionOnClick
                        autoPageSize
                        experimentalFeatures={{newEditingApi: true}}
                        processRowUpdate={this.onProcessRowUpdate.bind(this)}
                        initialState={{
                            sorting: {
                                sortModel: [{ field: 'den_art', sort: 'asc' }],
                            },
                        }}
                    />
                </div>
            </div>
        // }

        return grid;
    }
}